#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content1 .container .title {
  color: black;
}

#content .content1 .container .gallery {
  display: flex;
  gap: 2rem;
}

#content .content1 .container .gallery #ctaForm,
#content .content2 .container #ctaForm {
  position: absolute;
  margin-top: -6rem;
  margin-left: 2.5rem;
  text-align: left;
}

#content .content1 .container .gallery #ctaForm img {
  width: 16%;
}

#content .content2 .container #ctaForm img {
  width: 20%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container .gallery {
    flex-direction: column;
  }

  #content .content1 .container .gallery #ctaForm,
  #content .content2 .container #ctaForm {
    margin-top: -9rem;
    margin-left: 5rem;
  }

  #content .content1 .container .gallery #ctaForm img {
    width: 60%;
  }

  #content .content2 .container #ctaForm img {
    width: 60%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container .gallery #ctaForm,
  #content .content2 .container #ctaForm {
    margin-top: -7rem;
    margin-left: 2rem;
  }

  #content .content1 .container .gallery #ctaForm img {
    width: 80%;
  }

  #content .content2 .container #ctaForm img {
    width: 80%;
  }
}