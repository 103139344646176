#menu .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

#menu .container .left figure img {
    width: 60%;
}

#menu .container .right {
    display: flex;
    gap: 1rem;
    align-items: center;
}

#menu .container .right figure {
    width: 42%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        flex-direction: column;
        padding: 1rem 0;
    }

    #menu .container .left figure img {
        width: 80%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .right figure {
        width: 50%;
    }
}