#success {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#success .container .success-title {
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #success {
        background: url("../../assets/mobile/banner_topo.png") no-repeat 100% center;
        background-size: cover;
        height: 70vh;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}