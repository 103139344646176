#about {
  background-color: var(--color-red);
  color: white;
}

#about .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}