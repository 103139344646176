#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
}

#header .header1 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

#header .header1 .container .left {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

#header .header1 .container .left .title {
    width: 40%;
}

#header .header1 .container .left #ctaServices {
    text-align: left;
}

#header .mobile .container {
    display: none;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat 100% center;
        background-size: cover;
        height: 70vh;
    }

    #header .header1 .container {
        height: 100%;
    }

    #header .header1 .container .left .title {
        font-size: 3rem;
        width: 60%;
        text-align: left;
    }

    #header .header1 .container .right {
        display: none;
    }

    #header .mobile {
        background-color: #4E2323;
    }

    #header .mobile .container {
        display: block;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat 0 center;
        background-size: cover;
        height: 70vh;
    }

    #header .header1 .container .left .title {
        font-size: 2.5rem;
        width: 100%;
        text-align: celenter;
    }
}